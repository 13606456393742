import React from "react";
import { Layout } from "~components";

/** ============================================================================
 * @page
 * 404
 */
const PageNotFound = ({ location }) => (
  <Layout location={location}>
    <div>
      <></>
    </div>
  </Layout>
);

export default PageNotFound;
